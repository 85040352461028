<template>
  <div>
    <v-card class="px-8 pb-10 pt-5" color="white" flat>
      <v-card-title style="color: #008081">Cargando ...</v-card-title>
      <v-progress-linear color="primary accent-4"
                         height="6"
                         indeterminate
                         rounded>
      </v-progress-linear>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoadingGeneral",
}
</script>

<style scoped>

</style>
