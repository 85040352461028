<template>
  <div>
    <toolbar></toolbar>
    <!-- Filter  -->
    <vue-position-sticky>
      <v-sheet color="greyPrimary">
        <!-- region design xs-->
        <div class="hidden-sm-only hidden-xs-only">
          <v-sheet class="d-flex justify-center"
                   color="greyPrimary">
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-select v-model="filtersOptions.tempCategory" :items="businessCategories"
                            clearable
                            dense
                            hide-details
                            label="Categoría"
                            outlined
                            rounded
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select v-model="filtersOptions.tempState" :item-text="'name'"
                            :items="businessStates"
                            clearable
                            dense
                            hide-details
                            label="Provincia"
                            outlined
                            rounded
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select v-model="filtersOptions.tempCity" :items="availableCities"
                            clearable
                            dense
                            hide-details
                            label="Municipio"
                            outlined
                            rounded
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn
                      :disabled="filtersEnabled === false"
                      :loading="businessLoading"
                      block
                      class="text-none"
                      color="secondary"
                      depressed
                      large
                      rounded
                      @click="applyFilters()">
                    <span>Filtrar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </div>
        <!-- endregion-->

        <!-- region design sm -->
        <div class="d-md-none">
          <v-container class="text-center">
            <v-dialog v-model="dialog1"
                      fullscreen
                      hide-overlay
                      persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="text-none"
                       color="grey"
                       outlined
                       rounded
                       v-bind="attrs"
                       v-on="on">
                  <span style="color: #008081">¿Que deseas buscar?</span>
                </v-btn>
              </template>

              <v-card style="position: relative">
                <div>
                  <v-toolbar flat>
                    <v-btn icon
                           @click="closeFilters">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="font-weight-medium">Filtros</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none"
                           color="error"
                           large
                           rounded
                           text
                           @click="clearFilters()">
                      Borrar
                    </v-btn>
                  </v-toolbar>
                  <v-divider></v-divider>
                </div>
                <div class="text-center px-8">
                  <p class="py-5 font-weight-medium">Filtra por una o más opciones y encuentra lo que buscas rápido</p>
                  <v-select v-model="filtersOptions.tempCategory"
                            :items="businessCategories"
                            clearable
                            label="Categoría"
                            outlined>
                  </v-select>
                  <v-select v-model="filtersOptions.tempState"
                            :item-text="'name'"
                            :items="businessStates"
                            clearable
                            label="Provincia"
                            outlined>
                  </v-select>
                  <v-select v-model="filtersOptions.tempCity"
                            :items="availableCities"
                            clearable
                            label="Municipio"
                            outlined>
                  </v-select>
                </div>

                <v-footer absolute class="py-5" color="white">
                  <v-btn :disabled="filtersEnabled === false"
                         :loading="businessLoading"
                         block
                         class="text-none"
                         color="primary"
                         large
                         rounded
                         @click="applyFilters()">
                    Mostrar resultados
                  </v-btn>
                </v-footer>
              </v-card>
            </v-dialog>
          </v-container>
        </div>
        <!-- endregion -->
        <v-divider></v-divider>
      </v-sheet>
    </vue-position-sticky>

    <!-- Business -->
    <v-sheet v-if="businessList.length > 0" class="px-5" color="greyPrimary">
      <v-container>
        <div class="text-center pt-6 pb-10">
          <h2 class="mb-1">Echa un vistazo a <span style="color: #008081">Negocios SeOferta</span></h2>
          <p class="text-body-1">Aquí puedes encontrar gran variedad de negocios que pueden ser de interés o
            inspiración para ti. </p>
        </div>
        <v-row>
          <v-col v-for="business in businessList" :key="business.id" cols="12" lg="3" md="4" sm="6">
            <div class="mb-2">
              <v-hover v-slot="{ hover }">
                <v-card :class="{ 'on-hover': hover }"
                        :elevation="hover ? 5: 2"
                        class="nameBusiness rounded-t-xl rounded-b-xl pa-0 text-left">
                  <div class="px-6 py-4">
                    <v-list class="py-0" flat>
                      <v-list-item :href="getBusinessUrl(business.slug)" :ripple="false" class="px-0 "
                                   target="_blank">
                        <v-list-item-avatar color="greyPrimary" size="55">
                          <v-img v-if="business.logo.thumb !== ''" :src="business.logo.thumb"></v-img>
                          <span v-else style="color: #008081; font-size: 32px; font-weight: bold">
                        {{ firstChar(business.name) }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content class="my-link-hover">
                          <h3>{{ business.name }}</h3>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <p class="text-body-2 pt-1 mb-0" style="height: 70px">
                      {{ business.description | truncate(85, ' ...') }}</p>
                    <div class="d-flex">
                      <div v-if="business.city !== ''" class="text-caption font-weight-medium mr-1"
                           color="greyPrimary"
                           small>
                        En {{ business.city }}
                      </div>
                      <v-divider class="mx-2" vertical></v-divider>
                      <div v-if="business.category !== ''" class="text-caption font-weight-medium mr-1"
                           outlined
                           small>
                        <span style="color: #00999a">{{ business.category }}</span>
                      </div>
                    </div>
                  </div>
                  <v-img :src="business.cover.path !== '' ? business.cover.path : require('@/assets/cover.jpg') "
                         max-height="200px" min-height="200px">
                  </v-img>
                </v-card>
              </v-hover>
            </div>
          </v-col>
        </v-row>
        <div class="text-center">
          <v-btn v-show="showLoadMoreBusinessButton"
                 :loading="businessLoading"
                 class="my-8 text-none"
                 color="primary"
                 outlined
                 rounded
                 text
                 @click="loadMoreBusiness()">Ver más negocios
          </v-btn>
        </div>
      </v-container>
    </v-sheet>
    <v-sheet v-if="businessList.length === 0 && businessLoading === false">
      <v-container class="text-center">
        <v-img :src="require('../assets/searching.svg')"
               contain
               max-height="300">
        </v-img>
        <h1>Ups! ... no se encontraron resultados</h1>
        <p class="mt-2" style="font-size: 20px">Por favor, intente otra búsqueda</p>

      </v-container>
    </v-sheet>
    <v-sheet v-if="businessLoading === true">
      <v-overlay>
        <loading-general></loading-general>
      </v-overlay>
    </v-sheet>


    <!-- Messenger plugin de chat Code -->
    <div id="fb-root"></div>
    <!-- Your plugin de chat code -->
    <div id="fb-customer-chat" class="fb-customerchat"></div>

    <Footer></Footer>
  </div>

</template>

<script>

import {getFirstChar} from "@/utils/data";
import services from "../services";
import LoadingGeneral from "@/components/LoadingGeneral";

const Toolbar = () => import(/* webpackPrefetch: true */ "../components/Toolbar.vue");
const Footer = () => import(/* webpackPrefetch: true */ "@/components/Footer");

export default {
  name: "Business",
  data: () => ({
    tags: [
      'Work',
      'Home Improvement',
      'Vacation',
      'Food',
      'Drawers',
      'Shopping',
      'Art',
      'Tech',
      'Creative Writing',
    ],
    dialog: false,
    dialog1: false,
    businessCategories: [],
    businessStates: [],
    businessList: [],
    filtersOptions: {
      category: "",
      count: 12,
      page: 1,
      state: "",
      city: "",

      tempState: "",
      tempCity: "",
      tempCategory: "",
    },
    businessLoading: false,
    showLoadMoreBusinessButton: true
  }),
  async created() {
    this.businessList = await this.loadBusiness();
    await this.loadBusinessFilters();
  },
  components: {
    LoadingGeneral,
    Toolbar,
    Footer,
  },
  methods: {
    firstChar: getFirstChar,
    loadMoreBusiness: async function () {
      this.filtersOptions.page += 1;
      const res = await this.loadBusiness();
      for (let i = 0; i < res.length; i++) {
        const exist = this.businessList.find(item => item.id === res[i].id)
        if (!exist)
          this.businessList.push(res[i]);
      }
    },
    loadBusiness: async function () {
      try {
        this.businessLoading = true;
        const result = await services.business.getAllBusiness(this.filtersOptions);
        this.businessLoading = false;
        if (result && result.data) {
          this.showLoadMoreBusinessButton = result.data.length;
          return result.data;
        }
        return [];
      } catch (error) {
        console.log(error);
        this.businessLoading = false;
        return [];
      }
    },
    loadBusinessFilters: async function () {
      try {
        const result = await services.business.getBusinessFilterData();
        this.businessCategories = result.data.categories || [];
        this.businessStates = result.data.states || [];
      } catch (error) {
        console.log(error);
      }
    },
    changeCategory: async function (cat) {
      if (this.filtersOptions.category === cat) {
        this.filtersOptions.category = ""
        this.filtersOptions.tempCategory = ""
      } else {
        this.filtersOptions.category = cat
        this.filtersOptions.tempCategory = cat
      }
      await this.applyFilters();
    },
    clearFilters: async function () {
      this.filtersOptions.city = "";
      this.filtersOptions.state = "";

      this.filtersOptions.tempCity = "";
      this.filtersOptions.tempState = "";

      // reset category only in mobile dialog
      if (this.dialog1 === true) {
        this.filtersOptions.category = "";
        this.filtersOptions.tempCategory = "";
      }
    },
    closeFilters: async function () {
      this.dialog = false;
      this.dialog1 = false;
      this.filtersOptions.tempState = this.filtersOptions.state;
      this.filtersOptions.tempCity = this.filtersOptions.city;
      this.filtersOptions.tempCategory = this.filtersOptions.category;
    },
    applyFilters: async function () {
      this.businessLoading = true;

      this.filtersOptions.state = this.filtersOptions.tempState;
      this.filtersOptions.city = this.filtersOptions.tempCity;
      this.filtersOptions.category = this.filtersOptions.tempCategory;

      this.filtersOptions.page = 1; // reset page

      this.businessList = await this.loadBusiness();

      this.businessLoading = false;

      this.dialog = false;
      this.dialog1 = false;
    },
    getBusinessUrl: function (slug) {
      return window.location.origin + "/" + slug;
    }
  },
  filters: {
    truncate: function (value, length, suffix) {
      if (value.length > length) {
        let trimmedString = value.substr(0, length);
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + suffix;
      } else {
        return value;
      }
    },
  },
  computed: {
    availableCities: function () {
      const state = this.businessStates.find(state => state.name === this.filtersOptions.tempState)
      return state && state.cities || [];
    },
    filtersEnabled: function () {
      return this.filtersOptions.tempCategory !== null && this.filtersOptions.tempCategory.length > 0 ||
          this.filtersOptions.category !== null && this.filtersOptions.category.length > 0 ||
          this.filtersOptions.tempState !== null && this.filtersOptions.tempState.length > 0 ||
          this.filtersOptions.state !== null && this.filtersOptions.state.length > 0 ||
          this.filtersOptions.tempCity !== null && this.filtersOptions.tempCity.length > 0 ||
          this.filtersOptions.city !== null && this.filtersOptions.city.length > 0
    }
  },
};
</script>

<style>
.v-card {
  transition: all .3s cubic-bezier(0, 0, .5, 1);
}

.v-card.on-hover {
  transform: scale(1.01);
}

.nameBusiness h2 {
  font-size: 20px;
}

.my-link-hover h3:hover, a:hover {
  color: #008081;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 8px 0 18px;
}
</style>
